<template>
    <div class="container">
        <div class="title l mt-6">Events</div>
        <!-- {{filteredNews}}
         Filter: 
        <div v-for="tag in tags" :key='tag' class="tag is-primary mx-1">
            {{tag}}
        </div>-->
        <template v-for="(article, index) in allNewsArticles">
            <NewsTile v-if="index < maxItems"
                      :article="article"
                      :key="index" />
        </template>

        <button v-if="showMoreButton"
                @click="maxItems = 100; showMoreButton = false"
                class="button is-primary is-radiusless has-text-weight-bold is-block mt-4 primary-button">Show older
            events</button>
    </div>
</template>

<script>
import NewsTile from '@/components/newsTile.vue'

export default {
    name: 'News',
    components: {
        NewsTile
    },
    data: () => {
        return {
            maxItems: 5,
            showMoreButton: true
            // selectedTag: 'PeBL'
        }
    },
    metaInfo: {
        // Default subtitle if no subcomponent (%s) is defined by other page views
        title: 'News & Events',
        // all titles will be injected into this template unless template is set to null
        titleTemplate: 'Eduworks | %s'
    },
    mounted() {
        if (this.$store.state.allNewsArticles.length < 1)
            this.$store.dispatch('getAllNewsArticles')
    },
    computed: {
        allNewsArticles() {
            return this.$store.state.allNewsArticles
        }
    },

    methods: {
        notEmpty(data) {
            // check if optional strapi data item has a value
            // ex: v-if="notEmpty(job.howToApply)"
            if (!data) return false
            return true
        },
        getImgUrl(pic) {
            if (!pic) return
            return require('../assets/' + pic)
        },
        sortByDate(articles) {
            let sortedArticles = articles.sort(function (a, b) {
                return a.published_at > b.published_at
                    ? -1
                    : a.published_at < b.published_at
                        ? 1
                        : 0
            })
            return sortedArticles
        }
    }
}
</script>

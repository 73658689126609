<template>
    <!-- Single News teaser tile used in other pages -->
    <div :class="{'is-draft':article.published_at === null}">
        <div
            class="section news-entry"
            :style="{ backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, .2), rgba(255, 255, 255, .2)), url(${backgroundImageUrl() + image})` }"
        >
            <div class="tile is-5 news-tile is-block p-5">
                <div
                    class="tag is-primary has-text-weight-bold mb-4 is-radiusless mr-3"
                    v-for="articleTag in article.tags"
                    :key="articleTag.id"
                >{{ articleTag.tag }}</div>
                <div class="title is-size-4 mb-4 line-height-1 line-clamp">{{article.title}}</div>
                <p class="line-clamp line-height-2">{{stripHtml(article.body)}}</p>

                <div v-if="article.externalURL">
                    <a
                        class="button has-text-weight-bold transparent-button"
                        :href="'//' + article.externalURL"
                        target="_blank"
                    >
                        <span
                            v-if="article.externalNewsSource"
                        >Read on {{article.externalNewsSource}}</span>
                        <span v-else>Read Article</span>
                        <span class="icon ml-0">
                            <i class="fas fa-arrow-right ml-0"></i>
                        </span>
                    </a>
                </div>

                <div v-else>
                    <router-link
                        v-if="!article.externalURL"
                        :to="{ path: '/event/' + article.url }"
                        class="button has-text-weight-bold transparent-button"
                    >
                        <span>Read Article</span>

                        <span class="icon ml-0">
                            <i class="fas fa-arrow-right ml-0"></i>
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// NOTE for Emily:
// There are two ways to pass data from a parent to a child component like this.
// (1) Pass individual props which match up to slot IDs in the child component.
//     This is best when the parent page should decide what content goes into the child compoenent,
//     for example: passing HTML content to a modal window component.
// (2) Pass a whole data object, in this case a single news article (and all it's strapi json data inside)
//     This way the child component can decide how to handle the data.
// This 2nd approach makes it easier to pass the NewsTile some kind of context flag
// in case you want the same news tile to look slightly different on different pages but otherwise behave the same.

export default {
    name: 'NewsTile',
    props: ['article'],
    data: () => {
        return {}
    },
    computed: {
        image() {
            if (this.article.image) {
                if (this.article.image.formats.medium) {
                    return this.article.image.formats.medium.url
                }
                if (this.article.image.formats.large) {
                    return this.article.image.formats.large.url
                } else {
                    return this.article.image.url
                }
            } else return null
        }
    },
    methods: {
        backgroundImageUrl() {
            // if (article.peblBlog == true) {
            //     return this.$store.state.peblApiUrl
            // } else {
                return this.$store.state.strapiApiUrl
            // }
        },
        stripHtml(text) {
            let cleanText = text.replaceAll(
                /<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi,
                ''
            )
            cleanText = cleanText.replaceAll('*', '')
            cleanText = cleanText.replace(/ *\([^)]*\) */g, '')
            cleanText = cleanText.replace(/[[\]']+/g, '' + ' ')
            cleanText = cleanText.replaceAll('#', '')
            return cleanText
        }
    }
}
</script>

